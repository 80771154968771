@use '@ngxpert/hot-toast/src/styles/styles.scss';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'primeicons/primeicons.css';

@layer tailwind-base, tailwind-utilities, primeng;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

:root {
  --topnav-height: 56px;
}

.flash {
  display: block;
  animation: flash-animation 1s ease-out;
}

@keyframes flash-animation {
  0% {
    background-color: yellow;
  }
  40% {
    background-color: yellow;
  }
  100% {
    background-color: transparent;
  }
}

/**
 * Custom scrollbar
 */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f9a8d4;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f472b6;
}

.p-tooltip {
  --p-tooltip-background: rgba(255, 255, 255, 0.7);
  --p-tooltip-color: #ed107d;
}
